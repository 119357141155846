import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";

@Component({
  selector: 'app-how-to-use',
  templateUrl: './how-to-use.component.html',
  styleUrls: ['./how-to-use.component.css']
})
export class HowToUseComponent implements OnInit {

  constructor(private router: Router, ) { }

  ngOnInit(): void {
  }

  redirectToCreate() {
    this.router.navigate(['/dashboard/createform']);
  }

}
