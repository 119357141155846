<div class="beauty_form formHeight">
  <!-- Navbar header -->

  <!--  End Header -->
  <section class="col-lg-6 col-md-10 col-sm-12 mx-auto page_box form_beauty overflow-hidden rounded-0">
    <div class="row">
      <div class="col-lg-12">
        <div class="card rounded-0 ">
          <!-- Page Header-->
          <div class="card-header p-md-0">
            <div class="form_detials">
              <div class="form_name">
                <h2 class="text-center mb-0  text-capitalize">{{formName}}</h2>
              </div>
              <div class=" card-body">
              <div class="col-md-12 col-sm-12 text-center sally_beauty p-0">

                <div class="row">
                  <div class="col-sm-10 col-md-10 col-lg-5 mx-auto">
                    <div class="form-group">
                      <div *ngIf="merchantName" class="merchantName"> {{ merchantName}} </div>
                      <div *ngIf="imageUrl" class=" file_preview"><img [src]="imageUrl" *ngIf="imageUrl"
                          class="imageHeight">
                      </div>
                    </div>
                  </div>
                </div>

                <h5 *ngIf="!showAmountField">Please Complete</h5>
                <p *ngIf="!showAmountField">Payment of amount </p>
                <p class="price-text" *ngIf="!showAmountField"> <strong> ${{ amount }}</strong></p>
                <div class=" d-flex justify-content-center align-items-center">
                  <div class="mx-auto">
                    <div class="form-group" *ngIf="showAmountField">
                      <h5> Please enter the $ amount</h5>
                      <input class="amtInput form-control amountField text-center" [(ngModel)]="userEnteredAmount"
                        (keyup)="amountEntered($event)" (click)="amountFieldClicked()" />
                      <div class="input-errors mt-2 position-static" *ngIf="amountError">
                        Amount is required
                      </div>
                      <div class="input-errors mt-2 position-static" *ngIf="decimalError">
                        Enter valid amount(2 decimal places allowed)
                      </div>
                    </div>
                  </div>


                </div>
                <div *ngIf="(processingFeeType == 2) || (processingFeeType == 3)">
                  <div class=" mx-auto custom-checkbox-check">
                    <div class="form-group d-flex align-items-center justify-content-center">
                      <p class="mb-0 w-auto mr-2">{{labelText}}</p>
                      <input *ngIf="processingFeeType == 2" type="checkbox" [(ngModel)]="processingCheck"
                        (change)="calculateFee($event)">
                    </div>
                  </div>
                </div>



                <div class="row" *ngIf="(processingFeeType == 2) || (processingFeeType == 3)">
                  <div class="col-xl-6 col-md-7 mx-auto my-auto">
                    <p class="text-md-right text-center">Your card will be charged this amount</p>
                  </div>
                  <div class="col-xl-6 col-md-5  mx-auto">
                    <div class="form-group">
                      <div class="form-control">
                        {{totalFee}}
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              </div>
            </div>
          </div>
          <!-- End Page Header-->
          <div class="card-body">
            <div class="row">
              <div class="col-lg-12">
                <div class="card">
                  <form rel="" action="" [formGroup]="customerForm" (ngSubmit)="submit()">
                    <div class="row">
                      <div class="col-sm-6 col-md-6 col-lg-6">
                        <div class="form-group">
                          <label>First Name<sup>*</sup></label>
                          <input type="text" placeholder="" formControlName="firstName" class="form-control">
                          <div class="input-errors" *ngIf="submitted && f.firstName.errors?.required">
                            First name is required
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6 col-md-6 col-lg-6">
                        <div class="form-group">
                          <label>Last Name<sup>*</sup></label>
                          <input type="text" placeholder="" class="form-control" formControlName="lastName">
                          <div class="input-errors" *ngIf="submitted && f.lastName.errors?.required">
                            Last name is required
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-12 col-md-12 col-lg-12">
                        <div class="form-group">
                          <label>Email<sup>*</sup></label>
                          <input type="email" placeholder="" class="form-control" formControlName="email">
                          <div class="input-errors" *ngIf="submitted && f.email.errors?.required">
                            Email is required
                          </div>
                          <div class="input-errors" *ngIf="submitted && f.email.errors?.email">
                            Email must be a valid email address
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">

                      <div class="col-sm-6 col-md-6 col-lg-6">
                        <div class="form-group">
                          <label> Address<sup>*</sup></label>
                          <input type="text" placeholder="" class="form-control" formControlName="deliveryAddress">
                          <div class="input-errors" *ngIf="submitted && f.deliveryAddress.errors">
                            Please enter address
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6 col-md-6 col-lg-6">
                        <div class="form-group ">
                          <label>City<sup>*</sup></label>

                          <input type="text" placeholder="" class="form-control" formControlName="city">
                          <div class="input-errors" *ngIf="submitted &&f.city.errors">
                            Please enter city
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-6 col-md-6 col-lg-6">
                        <div class="form-group">
                          <label>State<sup>*</sup></label>
                          <input type="text" placeholder="" class="form-control" formControlName="state">
                          <div class="input-errors" *ngIf="submitted  && f.state.errors">
                            Please enter state
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6 col-md-6 col-lg-6">
                        <div class="form-group ">
                          <label>Zip Code<sup>*</sup></label>

                          <input type="text" placeholder="" class="form-control" formControlName="zipCode">
                          <div class="input-errors" *ngIf="submitted && f.zipCode.errors?.pattern">Enter only
                            numbers
                          </div>
                          <div class="input-errors" *ngIf="submitted && f.zipCode.errors">
                            Please enter zip code
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-sm-12 col-md-12 col-lg-12">
                        <label>Note<sup *ngIf="isMessageRequired">*</sup> <span *ngIf="messageFromMerchant"> - {{
                            messageFromMerchant }}</span>
                        </label>
                        <div class="form-group mt-2">
                          <textarea class="form-control" formControlName="message">
                                                    </textarea>
                          <div class="input-errors" *ngIf="submitted && isMessageRequired &&f.message.errors">Enter
                            the message
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Removed Recaptcha v2 -->
                    <div class="row">
                      <div class="col-sm-12 col-md-12 col-lg-12 ">
                        <div class="form-group ">
                          <ngx-recaptcha2 [siteKey]="siteKey" formControlName="recaptcha">
                          </ngx-recaptcha2>
                          <div class="input-errors" *ngIf="submitted && f.recaptcha.errors">
                            Please click on the captcha.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-12 co-md-12">
                        <div class="form-group mt-2">
                          <input type="submit" value="Go To Credit Card Form"
                            class="btn btn-primary w-100 rounded-0 p-3">
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>


<ngx-loading [show]="loading" class="overlay" *ngIf="loading"
  [config]="{animationType: ngxLoadingAnimationTypes.circle, primaryColour: primaryColour, secondaryColour: secondaryColour, backdropBorderRadius: '3px'}">
</ngx-loading>
