import { Component, OnInit } from '@angular/core';
import { PaymentService } from '../../services/payment.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ngxLoadingAnimationTypes, NgxLoadingComponent } from 'ngx-loading';
import { NotificationService } from '../../services/notification.service';
import { Router } from "@angular/router";
import { environment } from 'src/environments/environment';


declare let Clover: any;
@Component({
  selector: 'app-credit-card-form',
  templateUrl: './credit-card-form.component.html',
  styleUrls: ['./credit-card-form.component.css']
})
export class CreditCardFormComponent implements OnInit {

  siteKey = environment.siteKey;
  recaptcha:any = '';

  constructor(private paymentService: PaymentService, private formBuilder: FormBuilder, private notifyService: NotificationService, private router: Router) { }

  styles = {
    body: {
      fontFamily: 'Roboto, Open Sans, sans-serif',
      fontSize: '16px',
    },
    input: {
      fontSize: '16px',

      padding: '0px',
      margin: '0px'
    },
  };

  clover;
  elements;
  cardNumber;
  cardDate;
  cardCvv;
  cardPostalCode;

  customerDetails;

  detailsError;
  notEntered;
  crediCardForm;
  submitted = false;
  paymentId;


  loading = false;
  primaryColour = '#ffffff';
  secondaryColour = '#ccc';
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;

  ngOnInit(): void {
    this.submitted = false;
    this.card();

    this.customerDetails = this.paymentService.customerDetails;

  }

  card() {
    var apiKey = sessionStorage.getItem("apiKey")
    this.paymentId = sessionStorage.getItem("paymentId");

    this.clover = new Clover(apiKey);

    this.elements = this.clover.elements();

    this.detailsError = false;
    this.notEntered = false;

    this.cardNumber = this.elements.create('CARD_NUMBER', this.styles);
    this.cardDate = this.elements.create('CARD_DATE', this.styles);
    this.cardCvv = this.elements.create('CARD_CVV', this.styles);
    this.cardPostalCode = this.elements.create('CARD_POSTAL_CODE', this.styles);

    this.cardNumber.mount('#card-number');
    this.cardDate.mount('#card-date');
    this.cardCvv.mount('#card-cvv');
    this.cardPostalCode.mount('#card-postal-code');

    this.cardNumber.addEventListener('change', function (event) {
      document.getElementById('card-number-errors').innerHTML = event.CARD_NUMBER.error || '';
    });

    this.cardDate.addEventListener('change', function (event) {
      document.getElementById('card-date-errors').innerHTML = event.CARD_DATE.error || '';
    });

    this.cardCvv.addEventListener('change', function (event) {
      document.getElementById('card-cvv-errors').innerHTML = event.CARD_CVV.error || '';
    });

    this.cardPostalCode.addEventListener('change', function (event) {
      document.getElementById('card-postal-code-errors').innerHTML = event.CARD_POSTAL_CODE.error || '';
    });

  }

  async pay(event) {
    event.preventDefault();
    event.stopPropagation();
    this.submitted = true;
    if(!this.recaptcha){
      return
    }
    var tokenResponse = await this.generateToken();
    this.paymentService.paymentId = this.paymentId;

    if (tokenResponse.errors) {

      if (tokenResponse.errors.CARD_DATE) {
        document.getElementById('card-date-errors').innerHTML = tokenResponse.errors.CARD_DATE || ''
      }
      if (tokenResponse.errors.CARD_NUMBER) {
        document.getElementById('card-number-errors').innerHTML = tokenResponse.errors.CARD_NUMBER || '';
      }
      if (tokenResponse.errors.CARD_CVV) {
        document.getElementById('card-cvv-errors').innerHTML = tokenResponse.errors.CARD_CVV || '';
      }
      if (tokenResponse.errors.CARD_POSTAL_CODE) {
        document.getElementById('card-postal-code-errors').innerHTML = tokenResponse.errors.CARD_POSTAL_CODE || '';
      }

    }
    else {
      var token1 = tokenResponse.token;

      var date = new Date()
      var year = date.getUTCFullYear()
      var month = date.getMonth();

      if (tokenResponse.card.exp_year < year) {
        document.getElementById('card-date-errors').innerHTML = "Card is expired"
        return;
      }
      else {
        if (tokenResponse.card.exp_month <= month + 1 && tokenResponse.card.exp_year == year) {
          document.getElementById('card-date-errors').innerHTML = "Card is expired"
          return;
        }
      }

      var details = {
        "currency": "usd",
        "amount": this.paymentService.amount,
        "source": token1,
        "customerData": this.customerDetails,
        "merchantId": this.paymentService.merchantId,
        "paymentFormId": this.paymentId,
        "captchaToken": this.recaptcha,
        "_csrf": sessionStorage.getItem('csrfToken')
      }
      this.loading = true;
      this.paymentService.chargeCustomer(details).subscribe((response: any) => {
        this.loading = false;
        this.notifyService.showSuccess("Payment successful!! ", "");
        sessionStorage.clear();
        this.router.navigate(['/paymentSuccessful']);
        sessionStorage.setItem("paymentSuccess", "yes");
      }, err => {
        this.loading = false;
        if (err.status == 0) {
          this.notifyService.showError("Please provide valid card details.", "");
        }
        if (err.status == 402 || err.status == 500) {
          this.notifyService.showError(err.error.message, "");
        }
        if (err.status == 400) {
          this.notifyService.showError(err.error.msg, "");
        }
      })
    }
  }

  async generateToken() {
    var res;
    await this.clover.createToken()
      .then(function (result) {
        res = result;
      })
      .catch(function (data) {
        this.notifyService.showError("Error connecting with server. Please try after sometime.", "");
      });
    return res;
  }

}
