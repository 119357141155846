import { NgModule } from '@angular/core';
import { Routes, RouterModule, CanActivate } from '@angular/router';
import { DashboardComponent } from './paymentApp/dashboard/dashboard.component';
import { SideNavComponent } from './paymentApp/dashboard/side-nav/side-nav.component';
import { UsageLogsComponent } from './paymentApp/dashboard/usage-logs/usage-logs.component';
import { AccountSettingsComponent } from './paymentApp/dashboard/account-settings/account-settings.component';
import { HowToUseComponent } from './paymentApp/dashboard/how-to-use/how-to-use.component';
import { PaymentSafetyInfoComponent } from './paymentApp/dashboard/payment-safety-info/payment-safety-info.component';
import { CreateNewFormComponent } from './paymentApp/dashboard/create-new-form/create-new-form.component';
import { PaymentsFormComponent } from './paymentApp/dashboard/payments-form/payments-form.component';
import { SendEmailComponent } from './paymentApp/dashboard/send-email/send-email.component';
import { CustomerFormComponent } from './paymentApp/customer-form/customer-form.component';
import { CreditCardFormComponent } from './paymentApp/credit-card-form/credit-card-form.component';
import { PagenotfoundComponent } from './paymentApp/dashboard/pagenotfound/pagenotfound.component';
import { OnlyLoggedInUserGuard, paymentGuard, paymentGuardSuccess, verifySessionGuard } from './guards/guards';
import { PaymentSuccessComponent } from './paymentApp/payment-success/payment-success.component';

const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: 'customerForm', component: CustomerFormComponent },
  { path: 'cardpayment', component: CreditCardFormComponent, canActivate: [paymentGuard] },
  { path: 'paymentSuccessful', component: PaymentSuccessComponent, canActivate: [paymentGuardSuccess] },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [verifySessionGuard],
    children: [
      {
        path: '', redirectTo: 'createform', pathMatch: 'full'
      },
      { path: 'usagelogs', component: UsageLogsComponent, canActivate: [OnlyLoggedInUserGuard] },
      { path: 'accountsettings', component: AccountSettingsComponent, canActivate: [OnlyLoggedInUserGuard] },
      { path: 'how-to-use', component: HowToUseComponent, canActivate: [OnlyLoggedInUserGuard] },
      { path: 'payment-safety-info', component: PaymentSafetyInfoComponent, canActivate: [OnlyLoggedInUserGuard] },
      { path: 'createform', component: CreateNewFormComponent },
      { path: 'payments', component: PaymentsFormComponent, canActivate: [OnlyLoggedInUserGuard] },
      { path: 'email', component: SendEmailComponent, canActivate: [OnlyLoggedInUserGuard] },
    ]
  },
  { path: '**', component: PagenotfoundComponent }

  // { path: 'usagelogs', component: UsageLogsComponent, canActivate: [OnlyLoggedInUserGuard] },
  // {
  //   path: 'accountsettings', component: AccountSettingsComponent,
  //   canActivate: [OnlyLoggedInUserGuard]
  // },
  // { path: 'how-to-use', component: HowToUseComponent, canActivate: [OnlyLoggedInUserGuard] },
  // { path: 'payment-safety-info', component: PaymentSafetyInfoComponent, canActivate: [OnlyLoggedInUserGuard] },
  // { path: 'createform', component: CreateNewFormComponent },
  // { path: 'payments', component: PaymentsFormComponent, canActivate: [OnlyLoggedInUserGuard] },
  // { path: 'email', component: SendEmailComponent, canActivate: [OnlyLoggedInUserGuard] },
  // { path: 'customerForm', component: CustomerFormComponent },
  // { path: 'cardpayment', component: CreditCardFormComponent },
  // { path: 'paymentSuccessful', component: PaymentSuccessComponent },
  // { path: '', redirectTo: '/createform', pathMatch: 'full' },
  // { path: '**', component: PagenotfoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

