import { Component, HostListener, OnInit } from '@angular/core';
import { MainServiceService } from '../../../services/main-service.service';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { NotificationService } from '../../../services/notification.service';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-create-new-form',
  templateUrl: './create-new-form.component.html',
  styleUrls: ['./create-new-form.component.css']
})
export class CreateNewFormComponent implements OnInit {

  constructor(public dialog: MatDialog, private mainService: MainServiceService, private formBuilder: FormBuilder, private notifyService: NotificationService, private route: ActivatedRoute) { }

  createNewForm: FormGroup;
  submitted = false;
  disableCopyBtn = true;

  isNotesRequired = false;
  // isAddressRequired = false;
  body;
  loading = false;
  primaryColour = '#ffffff';
  secondaryColour = '#ccc';
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  token: string = ''

  yesNo = [{
    "status": "No",
  },
  {
    "status": "Yes"
  }]

  processingFee = [{
    "status": "No",
    "type": 1
  },
  {
    "status": "Yes - make optional for customers to pay for the merchant",
    "type": 2
  },
  {
    "status": "Yes - make required - client must pay fee.",
    "type": 3
  }]

  processingFeeValue  = 0;

  ngOnInit(): void {
    this.loading = false;
    this.disableCopyBtn = true;

    this.isNotesRequired = false;
    // this.isAddressRequired = false;

    this.createNewForm = this.formBuilder.group({
      formName: ['', Validators.required],
      amount: [''],
      customerNotes: ['No'],
      processingFeeType: [1],
      // shippingAddress: ['No'],
      merchantMsg: [''],
      url: ['']
    });
    this.token = this.route.snapshot.queryParams.token;
    this.getAccountSettings();
  }

  getAccountSettings()  {
    this.mainService.getAccountSettings(this.token).subscribe((response: any) => {
      if (response.data) {
        this.processingFeeValue = response.data.settings.cardProcessingFee;
        // this.processingFee[1].status = `Yes - make optional ${this.processingFeeValue}% available for customer to pay for merchant.`
      }
    })
  }

  get f() {
    return this.createNewForm.controls;
  }

  customerNotes(event) {
    if (event.target.value.toLowerCase() == "yes") {
      this.isNotesRequired = true;
    }
    else {
      this.isNotesRequired = false;
    }
  }
  // shippingAddress(event) {
  //   if (event.target.value.toLowerCase() == "yes") {
  //     this.isAddressRequired = true;
  //   }
  //   else {
  //     this.isAddressRequired = false;
  //   }
  // }

  feeConcentChange(event:any) {
    console.log('=====>', event.target.value);

  }

  submitCreateForm() {

    this.submitted = true;

    // stop here if form is invalid
    if (this.createNewForm.invalid) {
      return;
    }
    else {

      this.body = {
        "name": this.createNewForm.value.formName.trim(),
        "amount": this.createNewForm.value.amount,
        "isNotesRequired": this.isNotesRequired,
        "isAddressRequired": true,
        "message": this.createNewForm.value.merchantMsg.trim(),
        "processingFeeType": +this.createNewForm.value.processingFeeType || 1,
        "processingFee": this.processingFeeValue
      };
      if (!this.createNewForm.value.amount) {
        delete this.body['amount'];
      }
      if (this.body.processingFeeType === 1) {
        delete this.body['processingFee']
      }

      this.loading = true;
      this.mainService.createForm(this.body).subscribe((response: any) => {
        this.loading = false;

        if (response.statusCode == 200 && response.status == true) {
          this.notifyService.showSuccess("Form created successfully!! Click on Copy Url button to copy the payment url.", "");

          this.disableCopyBtn = false;
          this.createNewForm.patchValue({ url: response.paymentForm.url });
        }
      }, err => {
        this.loading = false;
        this.notifyService.showError("Error saving data. Please try after sometime.", "");
      })
    }
  }


  copyUrl(inputElement) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = inputElement;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.notifyService.showSuccess(" Url is copied.", "");
  }

  resetFields() {
    this.submitted = false;
    this.disableCopyBtn = true;
    this.createNewForm.patchValue({ formName: '' });
    this.createNewForm.patchValue({ amount: '' });
    this.createNewForm.patchValue({ customerNotes: ['No'] });
    // this.createNewForm.patchValue({ shippingAddress: ['No'] });
    this.createNewForm.patchValue({ merchantMsg: '' });
    this.createNewForm.patchValue({ url: '' });
  }

}
