<section class="page_box shadow overflow-hidden">
    <div class="row">
        <div class="col-lg-12">
            <div class="card account_set">
                <!-- Page Header-->
                <div class="card-header">
                    <div class="row align-items-center">

                        <div class="col-md-12 col-sm-12">
                            <h3 class="d-inline-block mb-0">How to Use</h3>
                        </div>

                    </div>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="card">

                                <p>Hay Now Pay Now makes obtaining payments online easy. We help you by creating a
                                    payment link which can be used to capture online payments easily. All payments are
                                    run using your existing Clover merchant account and will appear on your Clover
                                    device or corresponding Clover.com web dashboard. To use simply copy the link you
                                    create in Hay Now Pay Now and associate it as a hyperlink wherever you would like to
                                    accept payment online. The payment link can be used on your website, attached in
                                    emails, sent by text, or even used on social media. It is designed to be used as a
                                    pay or donate now link for your company.
                                </p>
                                <p>
                                    Our payment form also gathers information about your customer and their payment.
                                    Anytime a payment is made an email will be sent to the email addresses you provide
                                    us on the "Account Settings" tab in Hay Now Pay Now. Our email supplies you with
                                    notice that a payment was made and also supplies the information your customer
                                    provided. An email will also be generated and sent to your customer thanking them
                                    for the successful payment.</p>

                                <div class="btn-summary mt-3">
                                    <button class="btn btn-primary" (click)="redirectToCreate()">Get Started</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>