import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpXsrfTokenExtractor } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

@Injectable()
export class CustomInterceptor implements HttpInterceptor {
constructor(private tokenExtractor: HttpXsrfTokenExtractor) {
}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    req = req.clone({headers: req.headers.set('withCredentials', 'true')});
    // const cookieheaderName = 'X-CSRF-Token';
    // let csrfToken = this.tokenExtractor.getToken() as string;
    // if (csrfToken !== null && !req.headers.has(cookieheaderName)) {
    // req = req.clone({ headers: req.headers.set(cookieheaderName, csrfToken) });
  // }
  return next.handle(req);
  // .pipe(
  //   tap(
  //     (event: any) => {
  //       if (event instanceof HttpResponse) {
  //         console.log(req);
  //       }
  //   }));
  }
}
