// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // apiUrl: "http://35.229.114.233:3002",
  // apiUrl: "http://34.122.162.231:3000",
  // apiUrl: "http://55812e170795.ngrok.io",
  // frontEndUrl: "http://localhost:4203",
  frontEndUrl: "https://haynow.staging.chicmic.co.in",
  // siteKey: '6Le6qQwbAAAAALFvRMKLKYyxpHqpaJhjkz_700-2'
  // apiUrl: "http://192.180.3.104:5434",
  apiUrl: "https://api-haynow.staging.chicmic.co.in/",
  // frontEndUrl: "https://haynow.appcapable.com",
  // siteKey: '6Le6qQwbAAAAALFvRMKLKYyxpHqpaJhjkz_700-2',
  // siteKey: '6LfYkDMjAAAAAI9_VXsxI0zWNGW3DsD93A4gEWCJ',// production
  siteKey: '6LfkADIjAAAAAFEB40ZVTUNdlg8TftyAgJ8RKJpe', // development
  cloverUrl: 'https://sandbox.dev.clover.com' // dev
  // cloverUrl: 'https://www.clover.com' // production
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
