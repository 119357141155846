import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MainServiceService } from '../../../services/main-service.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ngxLoadingAnimationTypes, NgxLoadingComponent } from 'ngx-loading';
import { NotificationService } from '../../../services/notification.service';

@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.css']
})
export class AccountSettingsComponent implements OnInit {

  constructor(private mainService: MainServiceService, private formBuilder: FormBuilder, public dialog: MatDialog, private notifyService: NotificationService) { }

  accountSettingForm: FormGroup;
  submitted = false;
  loading = false;
  primaryColour = '#ffffff';
  secondaryColour = '#ccc';
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;

  diableSave = false;
  file = '';
  uploaded = false;
  url;

  ngOnInit(): void {
    this.loading = false;
    this.diableSave = false;
    this.file = '';
    this.uploaded = false;

    this.accountSettingForm = this.formBuilder.group({
      firstEmail: ['', [Validators.required, Validators.email]],
      secondEmail: ['', Validators.email],
      messageAfterPayment: [''],
      logo: [''],
      cardProcessingFee: ['3.5']
    })
    this.getAccountSettings();
  }

  getAccountSettings() {
    this.diableSave = false;
    this.loading = true;
    this.mainService.getAccountSettings().subscribe((response: any) => {
      this.loading = false;
      if (response.data) {

        if (response.data.settings.storeEmails.primary != undefined) {
          this.accountSettingForm.patchValue({ firstEmail: response.data.settings.storeEmails.primary });
        }
        if (response.data.settings.storeEmails.secondary != undefined) {
          this.accountSettingForm.patchValue({ secondEmail: response.data.settings.storeEmails.secondary });
        }
        if (response.data.settings.emailMessage != undefined) {
          this.accountSettingForm.patchValue({ messageAfterPayment: response.data.settings.emailMessage });
        }
        if (response.data.settings.cardProcessingFee != undefined) {
          this.accountSettingForm.patchValue({ cardProcessingFee: response.data.settings.cardProcessingFee });
        }
        this.url = response.data.logoFileUrl;
      }
    }, err => {
      this.loading = false;
      this.notifyService.showError("Error connecting to server. Please try after some time.", "")
    })
  }

  get f() {
    return this.accountSettingForm.controls;
  }

  onSave() {
    this.submitted = true;

    if (this.accountSettingForm.invalid) {
      return;
    }
    else {

      var secondEmail;
      var msg;
      if (this.accountSettingForm.value.secondEmail != undefined) {
        secondEmail = this.accountSettingForm.value.secondEmail.trim();
      }
      if (this.accountSettingForm.value.messageAfterPayment != undefined) {
        msg = this.accountSettingForm.value.messageAfterPayment.trim();
      }
      var body = {
        "settings": {
          "storeEmails": {
            "primary": this.accountSettingForm.value.firstEmail.trim(),
            "secondary": secondEmail
          },
          "emailMessage": msg,
          "cardProcessingFee": this.accountSettingForm.value.cardProcessingFee || 3.5,
        }
      }

      if (body.settings.emailMessage == "") {
        delete body.settings['emailMessage']
      }
      if (body.settings.storeEmails.secondary == '') {
        delete body.settings.storeEmails['secondary']
      }

      this.loading = true;
      this.mainService.editAccountSettings(body).subscribe((response: any) => {
        this.loading = false;

        if (response.statusCode == 200 && response.status == true) {
          this.notifyService.showSuccess("Data saved successfully !!", "");
          this.getAccountSettings();
        }
      }, err => {
        this.loading = false;
        this.notifyService.showError("Error in saving data. Please try after sometime.", "");
      })
    }

  }

  onSelectFile(event) {

    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      this.uploaded = false;

      reader.readAsDataURL(event.target.files[0]); // read file as data url
      this.file = event.target.files[0];
      reader.onloadend = (event) => {
        this.url = event.target.result;
      }

    }
  }

  uploadLogo(event) {

    event.preventDefault();

    let input = new FormData();
    input.append('file', this.file);

    this.loading = true;
    this.mainService.uploadLogo(input).subscribe((response: any) => {
      this.loading = false;
      this.uploaded = true;

      this.notifyService.showSuccess("Logo uploaded successfully.", "");
    }, err => {

      this.loading = false;
      this.uploaded = false;

      if (err.statusCode = 400) {
        this.notifyService.showError("You can upload only jpg or png file.", "");
      }
      else {
        this.notifyService.showError("Error in saving logo. Please try after sometime.", "");
      }
    })
  }

}

