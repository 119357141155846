<section class="page_box shadow overflow-hidden">
    <div class="row">
        <div class="col-lg-12">
            <div class="card account_set">
                <!-- Page Header-->
                <div class="card-header">
                    <div class="row align-items-center flex-md-row-reverse">
                        <div class="col-md-4 text-md-right text-center">
                            <input type="submit" value="Reset" class="btn btn-md btn-default" (click)="resetFields()" />
                        </div>
                        <div class="col-md-8 col-sm-12">
                            <h3 class="d-inline-block mb-0">Create a Pay Now or Donate Now form </h3>
                        </div>

                    </div>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="card">

                                <form rel="" action="" [formGroup]="createNewForm">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12 col-lg-12">
                                            <div class="form-group">
                                                <label class="col-sm-12 col-md-12 col-lg-12 p-0">Name of form
                                                </label>
                                                <input type="text" placeholder="" name="formName" title="formName"
                                                    formControlName="formName"
                                                    class="form-control col-sm-12 col-md-12 col-lg-6">
                                                <div class="input-errors" *ngIf="submitted && f.formName.errors">
                                                    Name is required.
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12 col-lg-12">
                                            <div class="form-group">
                                                <label class="col-sm-12 col-md-12 col-lg-12 p-0">Enter dollar amount
                                                    requested (optional)
                                                </label>
                                                <input type="number" placeholder="" name="amount" title="amount"
                                                    formControlName="amount"
                                                    class="form-control col-sm-12 col-md-12 col-lg-6">

                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="row">
                                        <div class="col-sm-12 col-md-12 col-lg-12">
                                            <div class="form-group">
                                                <label class="col-sm-12 col-md-12 col-lg-12 p-0">Do you require
                                                    delivery/shipping address?</label>
                                                <div class="custom-selectbox col-md-6 p-0">
                                                    <div class="btn-group w-100 ">

                                                        <select class="dropdown-toggle col-md-12 form-control "
                                                            aria-expanded="true" formControlName="shippingAddress"
                                                            (change)="shippingAddress($event)" name="shippingAddress">

                                                            <option class="dropdown-item" *ngFor="let item of yesNo"
                                                                value={{item.status}}>
                                                                {{ item.status }} </option>
                                                        </select>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> -->
                                    <div class="row">
                                      <div class="col-sm-12 col-md-12 col-lg-12">
                                          <div class="form-group">
                                              <label class="col-sm-12 col-md-12 col-lg-12 p-0">Would you like your customer to pay the processing fee?
                                              </label>
                                              <div class="custom-selectbox col-md-6 p-0">
                                                  <div class="btn-group w-100 ">

                                                      <select class="dropdown-toggle col-md-12 form-control "
                                                          aria-expanded="true" formControlName="processingFeeType"
                                                          (change)="feeConcentChange($event)" name="processingFeeType">

                                                          <option class="dropdown-item" *ngFor="let item of processingFee"
                                                              value={{item.type}}>
                                                              {{ item.status }} </option>
                                                      </select>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12 col-lg-12">
                                            <div class="form-group">
                                                <label class="col-sm-12 col-md-12 col-lg-12 p-0">Do you want to gather
                                                    notes from customer
                                                </label>
                                                <div class="custom-selectbox col-md-6 p-0">
                                                    <div class="btn-group w-100 ">

                                                        <select class="dropdown-toggle col-md-12 form-control "
                                                            aria-expanded="true" formControlName="customerNotes"
                                                            (change)="customerNotes($event)" name="customerNotes">

                                                            <option class="dropdown-item" *ngFor="let item of yesNo"
                                                                value={{item.status}}>
                                                                {{ item.status }} </option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12 col-lg-12">
                                            <div class="form-group">
                                                <label class="col-sm-12 col-md-12 col-lg-12 p-0">Enter the message you
                                                    want to communicate to customer
                                                </label>
                                                <input type="text" placeholder="" name="merchantMsg"
                                                    formControlName="merchantMsg"
                                                    class="form-control col-sm-12 col-md-12 col-lg-6" maxlength="40">

                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-12 co-md-12">

                                            <div class="form-group mt-2">
                                                <input type="submit" value="Create" class="btn btn-primary mr-2"
                                                    (click)="submitCreateForm()"
                                                    [ngClass]="disableCopyBtn? '' : 'disableBtn'">
                                                <input type="submit" value="Copy Url"
                                                    [ngClass]="disableCopyBtn? 'disableBtn' : ''"
                                                    (click)="copyUrl(inputurl.value)" class="btn btn-primary">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12 col-lg-12">
                                            <div class="form-group">

                                                <input type="text" disabled placeholder="Payment URL"
                                                    formControlName="url"
                                                    class="form-control col-sm-12 col-md-12 col-lg-6" #inputurl>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>
<ngx-loading [show]="loading" class="overlay" *ngIf="loading"
    [config]="{animationType: ngxLoadingAnimationTypes.circle, primaryColour: primaryColour, secondaryColour: secondaryColour, backdropBorderRadius: '3px'}">
</ngx-loading>
