<section class="page_box shadow overflow-hidden">
    <div class="row">
        <div class="col-lg-12">
            <div class="card account_set">
                <!-- Page Header-->
                <div class="card-header">
                    <div class="row align-items-center">

                        <div class="col-md-12 col-sm-12">
                            <h3 class="d-inline-block mb-0">Usage Logs</h3>
                        </div>

                    </div>
                </div>
                <!-- End Page Header-->

                <div class="card-body">
                    <div class="float-right mb-2">
                        <!-- *ngIf="totalRecords>0" -->
                        <input type="text" placeholder="Search" (change)="searchLogs($event)" class="form-control"
                            [(ngModel)]="search">
                    </div>
                    <div class="table-responsive mobile_card">
                        <table class="table dataTable  table-hover " id="table_data" style="width:100%">
                            <thead>
                                <tr>
                                    <th scope="col" class=" sorting_asc">Form Name
                                        <!-- <div class="sorting">
                                            <i class="active"><img src="../../../../assets/icons/up_arrow.png"
                                                    alt="sort"></i>
                                            <i><img src="../../../../assets/icons/down_arrow.png" alt="sort"></i>
                                        </div> -->
                                    </th>
                                    <th scope="col" class=" sorting_asc">Data Used
                                    </th>
                                    <th scope="col" class=" sorting_asc">Customer Name
                                    </th>
                                    <th scope="col" class="text-center sorting_asc">$Amount paid
                                    </th>
                                    <th scope="col" class="text-center sorting_asc">Customer Notes
                                    </th>
                                    <th scope="col" class="text-center sorting_asc">Shipping
                                    </th>
                                </tr>
                            </thead>
                            <tbody *ngIf="totalRecords>0">
                                <tr
                                    *ngFor="let item of data | paginate: {itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalRecords}">
                                    <td data-th="Form Name" title={{item.paymentFormName}}>

                                        <div class=" ellipses paymentFormNameWidth">
                                            {{ item.paymentFormName? item.paymentFormName: 'NA' }}</div>
                                    </td>
                                    <td data-th="Data Used" title={{item.createdAt}}>
                                        {{ item.createdAt? item.createdAt: 'NA' }}
                                    </td>
                                    <td data-th="Customer Name" title={{item.name}}>
                                        <div class=" ellipses customerNameWidth">{{ item.name? item.name: 'NA' }}
                                          <br>  <span class="text-muted small" title={{item.email}}>{{ item.email? item.email: 'NA' }}</span>
                                        </div>
                                    </td>
                                    <td data-th="$ Amount paid" title={{item.amount}} class="text-center ">
                                        {{item.amount? item.amount: 'NA' }}
                                    </td>
                                    <td data-th="Customer Notes" class="text-center">
                                        <div class="notesWidth ellipses" title={{item.notes}}>
                                            {{ item.notes? item.notes: 'NA' }}
                                        </div>
                                    </td>

                                    <td data-th="Shipping" class="text-center " title={{item.shippingAddress}}>
                                        <div class=" ellipses shippingWidth">
                                            {{ item.shippingAddress? item.shippingAddress: 'NA' }}
                                        </div>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                        <pagination-controls class="paginationAlign" (pageChange)="nextPage($event)">
                        </pagination-controls>
                    </div>
                    <div *ngIf="!data?.length">No records found</div>
                </div>
            </div>
        </div>

    </div>
</section>
<ngx-loading [show]="loading" class="overlay" *ngIf="loading"
    [config]="{animationType: ngxLoadingAnimationTypes.circle, primaryColour: primaryColour, secondaryColour: secondaryColour, backdropBorderRadius: '3px'}">
</ngx-loading>
