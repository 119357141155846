<section class="page_box shadow overflow-hidden">
	<div class="row">
		<div class="col-lg-12">
			<div class="card account_set">
				<!-- Page Header-->
				<div class="card-header">
					<div class="row align-items-center">

						<div class="col-md-12 col-sm-12">
							<!-- <h3 class="d-inline-block mb-0">Send Email or Text</h3> -->
							<h3 class="d-inline-block mb-0">Send Email</h3>

						</div>

					</div>
				</div>
				<div class="card-body">
					<div class="row">
						<div class="col-lg-12">
							<div class="card">
								<form rel="" action="" [formGroup]="emailForm">
									<div class="row">
										<div class="col-sm-6 col-md-6 col-lg-6">
											<div class="form-group">
												<label>Name</label>
												<input type="text" placeholder="" class="form-control" name="customerName"
													title={{emailForm.value.customerName}} formControlName="customerName">
												<!-- [ngClass]="{ 'is-invalid': submitted && f.customerName.errors }" -->
												<div class="input-errors" *ngIf="submitted && f.customerName.errors">
													Name is required.
												</div>
											</div>
										</div>
										<!-- <div class="col-sm-6 col-md-6 col-lg-6">
											<div class="form-group">
												<label>Email/Phone number</label>
												<input type="email" placeholder="" class="form-control" name="email" title="email"
													formControlName="emailPhoneNo">
												<div class="input-errors" *ngIf="submitted && f.emailPhoneNo.errors?.required">
													Enter email or a phone number.
												</div>
												<div class="input-errors" *ngIf="submitted && f.emailPhoneNo.errors?.pattern">
													Enter a valid email or phone number.
												</div>
												<div class="input-errors" *ngIf="submitted && f.emailPhoneNo.errors?.validateEmail?.invalid">
													Enter a valid email or phone number.
												</div>

											</div>
										</div> -->

										<div class="col-sm-6 col-md-6 col-lg-6">
											<div class="form-group">
												<label>Email</label>
												<input type="email" placeholder="" class="form-control" name="email" title="email"
													formControlName="emailPhoneNo">
												<div class="input-errors" *ngIf="submitted && f.emailPhoneNo.errors?.required">
													Enter email.
												</div>
												<div class="input-errors"
													*ngIf="submitted && f.emailPhoneNo.errors?.validateEmail?.invalid && !f.emailPhoneNo.errors?.required">
													Enter a valid email.
												</div>

											</div>
										</div>
									</div>
									<div class="row">
										<div class="col-sm-6 col-md-6 col-lg-6">
											<div class="form-group ">
												<label>Select payment form to send</label>
												<div class="custom-selectbox">
													<div class="btn-group w-100 ">

														<select class="dropdown-toggle col-md-12 form-control " aria-expanded="true"
															formControlName="paymentId" name="paymentId" (change)="paymentFormIdChange($event)"
															title={{emailForm.value.paymentId}}>
															<!-- [ngClass]="{ 'is-invalid': submitted && f.paymentId.errors }" -->
															<option class="dropdown-item" value="" disabled>
															</option>
															<option class="dropdown-item" *ngFor="let item of paymentFormId" value={{item.formId}}
																title={{item.formName}},{{item.formId}}>
																{{ item.formName}}, {{ item.formId }} </option>
														</select>

													</div>
													<div class="input-errors" *ngIf="submitted && f.paymentId.errors">
														Select a payment form.
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="row">
										<div class="col-sm-12 col-md-12 col-lg-12">
											<label>Choose type of payment</label>
											<div class="form-group mt-2">
												<input type="submit" value="Pay Now" class="btn btn-outline-secondary mr-2"
													[ngClass]="payNowSelected? 'btnBackgroundColor' : ''" (click)="payNow()">
												<input type="submit" value="Donate Now" class="btn btn-outline-secondary"
													[ngClass]="donateNowSelected? 'btnBackgroundColor' : ''" (click)="donateNow()">
												<div class="input-errors" *ngIf="submitted && payDonateOption">
													Select an option.
												</div>
											</div>
										</div>
									</div>
									<!-- <div class="row">
										<div class="col-sm-12 col-md-12 col-lg-12">
											<div class="form-group mt-2">
												<label>Message</label>
												<textarea class="form-control" name="message" title="message"
													formControlName="message" title={{emailForm.value.message}}>

										        </textarea>
												<div class="input-errors" *ngIf="submitted && f.message.errors">
													Message is required.
												</div>
											</div>
										</div>
									</div> -->
									<div class="row">
										<div class="col-sm-12 co-md-12">
											<div class="form-group mt-2">
												<input type="submit" value="Send" class="btn btn-primary" (click)="sendEmail()">
											</div>
										</div>
									</div>
								</form>

							</div>
						</div>
					</div>
				</div>
				<!-- <div class="messageWarning">"By using the SMS feature of our app you agree to have read and agree to follow our
					SMS
					policy
					<a class="linkColor" href="https://www.appcapable.com/sms/" rel="noopener noreferrer" target="_blank">www.appcapable.com/sms</a>"
				</div> -->
			</div>
		</div>
	</div>
</section>

<ngx-loading [show]="loading" class="overlay" *ngIf="loading"
	[config]="{animationType: ngxLoadingAnimationTypes.circle, primaryColour: primaryColour, secondaryColour: secondaryColour, backdropBorderRadius: '3px'}">
</ngx-loading>