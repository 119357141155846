<app-side-nav *ngIf="!showHideDom"></app-side-nav>
<div id="content-wrapper" *ngIf="!showHideDom">
    <header class="header">
        <nav class="navbar">
            <div class="container-fluid">
                <div class="navbar-header d-flex align-items-center justify-content-start">
                    <a class="toggle-btn menu-btn hover" (click)="toggleSideNav()"><i class="fa fa-align-right">
                        </i></a>
                    <ul class="nav-menu list-unstyled d-flex flex-md-row align-items-md-center">

                        <li class="nav-item">
                            <h2 class="d-inline-block">Welcome to Hay Now</h2>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </header>
    <router-outlet></router-outlet>
</div>
<ngx-loading [show]="loading" class="overlay" *ngIf="loading"
    [config]="{animationType: ngxLoadingAnimationTypes.circle, primaryColour: primaryColour, secondaryColour: secondaryColour, backdropBorderRadius: '3px'}">
</ngx-loading>