import { Component, OnInit, ViewChild, TemplateRef, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { PaymentService } from '../../services/payment.service';
import { MainServiceService } from '../../services/main-service.service';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ngxLoadingAnimationTypes, NgxLoadingComponent } from 'ngx-loading';
import { NotificationService } from '../../services/notification.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-customer-form',
  templateUrl: './customer-form.component.html',
  styleUrls: ['./customer-form.component.css']
})
export class CustomerFormComponent implements OnInit, OnDestroy {

  customerForm: FormGroup;
  submitted = false;
  apiKey;
  paymentId;
  amount;
  formName;
  merchantId;

  loading = false;
  primaryColour = '#ffffff';
  secondaryColour = '#ccc';
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;

  url;
  userEnteredAmount;
  showAmountField = false;
  amountError = false;
  decimalError = false;
  isAddressRequired = false;
  isMessageRequired = false;
  imageUrl;
  merchantName;
  messageFromMerchant;
  subscription: Subscription;

  siteKey = environment.siteKey;
  amountRegex;
  numberRegex;

  labelText;
  processingFeeType;
  processingFee;
  totalFee;
  processingCheck;

  constructor(private activatedRoute: ActivatedRoute, private mainService: MainServiceService, private router: Router, private paymentService: PaymentService, private formBuilder: FormBuilder, private notifyService: NotificationService) { }

  ngOnInit(): void {
    // this.subscription = this.recaptchaV3Service.onExecute
    //   .subscribe((data: OnExecuteData) => {
    //     console.log("DATA: ", data);
    //   });

    sessionStorage.clear();
    this.loading = false;
    this.showAmountField = false;
    this.amountError = false;
    this.decimalError = false;
    this.isAddressRequired = false;
    this.isMessageRequired = false;
    this.imageUrl = "";
    this.merchantName = "";
    this.messageFromMerchant = "";
    this.amountRegex = (/^\d+(\.\d{2})?$/);

    this.customerForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      deliveryAddress: ['', Validators.required],
      city: ['', Validators.required],
      zipCode: ['', Validators.pattern("^[0-9]*$")],
      message: [''],
      state: ['', Validators.required],
      recaptcha: ['', Validators.required]
    })

    this.userEnteredAmount = '';
    this.url = window.document.URL;

    this.activatedRoute.queryParams.subscribe(parameter => {

      var keys = Object.keys(parameter)
      this.paymentService.paymentId = parameter.paymentFormId;
      this.paymentId = this.paymentService.paymentId;
      sessionStorage.setItem("paymentId", parameter.paymentFormId);

    })
    this.getPaymentDetails(this.paymentId);
  }

  getPaymentDetails(id) {

    this.loading = true;

    this.paymentService.getPaymentData(id).subscribe((response: any) => {
      sessionStorage.setItem('csrfToken', response.csrfToken);
      this.loading = false;

      if (response.data.merchantLogoUrl) {
        this.imageUrl = response.data.merchantLogoUrl;
      }

      if (response.data.amount != undefined) {
        this.amount = response.data.amount;
        this.paymentService.amount = this.amount;
        this.totalFee = this.amount || 0;
      }
      if (response.data.amount == 0 || response.data.amount == undefined) {
        this.showAmountField = true;
      }

      this.formName = response.data.name;
      this.merchantId = response.data.createdBy;
      this.paymentService.merchantId = this.merchantId;
      // this.isAddressRequired = response.data.isAddressRequired;
      this.isMessageRequired = response.data.isNotesRequired;
      this.merchantName = response.data.merchantName;
      this.messageFromMerchant = response.data.message;

      // if (this.isAddressRequired) {
      //   this.getAddressChanges();
      // }

      this.processingFeeType = response.data.processingFeeType;
      this.processingFee = response.data.processingFee;
      if(response.data.processingFeeType == 3) {
        this.processingCheck = true;
        this.calculateFee();
      }
      if(this.processingFeeType == 2) {
        this.labelText = `Please Check This Box to Pay Our Card Processing Fee`
      } else {
        this.labelText = `When Paying Online a ${this.processingFee}% fee is added. Your Card Will Be Charged Amount Below`
      }

      if (this.isMessageRequired) {
        this.getMessageChange();
      }
    }, err => {
      this.notifyService.showError("Error connecting to server. Please try after sometime.", '')
      this.loading = false;
    })

  }

  get f() {
    return this.customerForm.controls;
  }

  amountEntered(event) {
    this.decimalError = false;
    if (event.target.value == "") {
      this.amountError = true;
      this.decimalError = false;
    }
    else {
      if (this.amountRegex.test(event.target.value) == false) {
        this.decimalError = true;
        this.amountError = false;
      }
      else {
        this.amountError = false;
        this.decimalError = false;
      }
    }
    this.calculateFee();
  }

  click = false;
  amountFieldClicked() {
    if (this.amountError == false) {
      this.click = true;
    }
  }

  // getAddressChanges() {
  //   const deliveryAddressControl = this.customerForm.get('deliveryAddress');
  //   deliveryAddressControl.setValidators([Validators.required]);
  //   deliveryAddressControl.updateValueAndValidity();

  //   const cityControl = this.customerForm.get('city');
  //   cityControl.setValidators([Validators.required]);
  //   cityControl.updateValueAndValidity();

  //   const stateAddressControl = this.customerForm.get('state');
  //   stateAddressControl.setValidators([Validators.required]);
  //   stateAddressControl.updateValueAndValidity();

  //   const zipCodeAddressControl = this.customerForm.get('zipCode');
  //   zipCodeAddressControl.setValidators([Validators.required]);
  //   zipCodeAddressControl.updateValueAndValidity();

  // }

  getMessageChange() {
    const messageControl = this.customerForm.get('message');
    messageControl.setValidators([Validators.required]);
    messageControl.updateValueAndValidity();
  }

  goToCreditCardForm() {
    this.submitted = true;
    this.paymentService.amount = this.totalFee;
    if (this.amount != undefined) {
      this.amountError = false;
    }
    else {
      if (this.userEnteredAmount == "" || this.userEnteredAmount == undefined) {
        this.amountError = true;
        this.decimalError = false;
      }
    }

    if (this.amount == undefined && ((this.userEnteredAmount != "" || this.userEnteredAmount != undefined) && this.decimalError == false)) {
      // this.paymentService.amount = this.userEnteredAmount;
      this.paymentService.amount = this.totalFee;
    }

    var address = '';

    if (this.customerForm.value.deliveryAddress != '') {
      address = this.customerForm.value.deliveryAddress.trim();
    }
    if (this.customerForm.value.city != '') {
      if (address != "") {
        address += ', ' + this.customerForm.value.city.trim();
      }
      else {
        address += this.customerForm.value.city.trim();
      }
    }
    if (this.customerForm.value.state != '') {
      if (address != "") {
        address += ', ' + this.customerForm.value.state.trim();
      }
      else {
        address += this.customerForm.value.state.trim();
      }
    }
    if (this.customerForm.value.zipCode != '') {
      if (address != "") {
        address += ' - ' + this.customerForm.value.zipCode.trim();
      }
      else {
        address += this.customerForm.value.zipCode.trim();
      }
    }

    var data = {
      "name": this.customerForm.value.firstName.trim() + " " + this.customerForm.value.lastName.trim(),
      "notes": this.customerForm.value.message.trim(),
      "shippingAddress": address,
      'email': this.customerForm.value.email.trim(),
      'city': this.customerForm.value.city.trim()
    }

    if (data.notes == '') {
      delete data['notes'];
    }
    if (data.shippingAddress == '') {
      delete data['shippingAddress'];
    }

    this.paymentService.customerDetails = data;

    if (this.customerForm.invalid || this.amountError || this.decimalError) {
      return;
    }
    else {
      console.log(this.paymentService)

      console.log(this.paymentService.amount)
      if (this.paymentService.amount) {

        this.loading = true;
        this.paymentService.getMerchantApiKey(this.customerForm.value.recaptcha).subscribe((response: any) => {
          this.loading = false;

          if (response.status == true && response.statusCode == "200") {
            this.apiKey = response.data.apiAccessKey;
            sessionStorage.setItem("apiKey", this.apiKey);
            this.router.navigate(['/cardpayment']);
          }
          else {
            this.notifyService.showError("Error connecting to server. Please try after some time.", "")
          }

        }, err => {
          this.loading = false;
          this.notifyService.showError("Error connecting to server. Please try after some time.", "")
        })
      }
      else {
        this.notifyService.showError("Please check the details.", "")
      }
    }
  }

  submit(): void {
    //? For recaptcha v3 implementation
    // this.recaptchaV3Service.execute('customerFormSubmit')
    //   .subscribe((token) => {
    //     this.customerForm.controls.recaptcha.setValue(token);
    //     console.log(this.customerForm.controls)
    //     this.goToCreditCardForm()
    //   },
    //     (error) => {
    //       console.log(error)
    //     });

    //? For recaptcha v2 implementation
    if(this.customerForm.valid) {
      this.goToCreditCardForm();
    }

  }


  calculateFee() {
    console.log(this.userEnteredAmount, this.amount, this.processingFee, this.processingCheck);
    let amt = this.userEnteredAmount || this.amount;
    if(this.processingCheck) {
      this.totalFee = (amt * (this.processingFee/100)).toFixed(3) || 0;
      this.totalFee = (parseFloat(this.totalFee) + parseFloat(amt)) || 0;
      this.totalFee = this.totalFee.toFixed(2) || 0;
    } else {
      this.totalFee = amt || 0;
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
