import { Injectable } from '@angular/core';
import { HttpserviceService } from './httpservice.service';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  api = environment.apiUrl;
  customerDetails = {}
  customerAuthKey = "key_30c1e84ca224a80b4a499b15bc2ad79a_clover";
  merchantId;
  formName;
  amount;
  paymentId;
  isAddressRequired;
  isNotesRequired;

  constructor(private httpService: HttpserviceService) { }

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': this.customerAuthKey }),
    withCredentials: true,
  };

  apikey = "/v1/merchant/apiKey"

  getMerchantApiKey(recaptchaToken) {

    console.log("recaptchaToken: ", recaptchaToken)
    var api = this.api + this.apikey + "?merchantId=" + this.merchantId + "&captchaToken=" + recaptchaToken;
    var data = {
      api,
      httpOptions: this.httpOptions
    }
    return this.httpService.getApi(data);
  }

  payApi = "/v1/charge"
  chargeCustomer(body) {

    var api = this.api + this.payApi;
    var data = {
      body,
      "api": api,
      httpOptions: this.httpOptions
    }
    return this.httpService.postApi(data);
  }

  createFormPostApi = "/v1/paymentForm/";
  getPaymentData(id) {

    var api = this.api + this.createFormPostApi + id;
    var data = {
      api,
      httpOptions: this.httpOptions
    }
    return this.httpService.getApi(data);
  }

}
