<div mat-dialog-content class="content modal_popup">
    <div class="delete_modal">
        <i><img src="../../../assets/icons/close_icon.svg" alt="" width="40" /></i>
        <p>Do you want to delete the following record?</p>
        <div mat-dialog-actions class="row">
            <div mat-dialog-actions class="col-md-12 py-0 justify-content-center buttonGroup">
                <button mat-raised-button class="btn btn-md btn-primary" (click)="deleteRecord()"> Confirm </button>
                <button mat-raised-button class="btn btn-md btn-default" cdkFocusInitial (click)="close()"> Cancel
                </button>
            </div>
        </div>
    </div>
</div>