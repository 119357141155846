import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-payment-safety-info',
  templateUrl: './payment-safety-info.component.html',
  styleUrls: ['./payment-safety-info.component.css']
})
export class PaymentSafetyInfoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void { }

  goToPolicy() {
    window.open("https://www.appcapable.com/eua/", "_blank");
  }
}
