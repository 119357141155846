import { Component, OnInit, HostListener } from '@angular/core';
import { MainServiceService } from '../../services/main-service.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { PaymentService } from '../../services/payment.service';
import { environment } from '../../../environments/environment';
import { ngxLoadingAnimationTypes, NgxLoadingComponent } from 'ngx-loading';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})

export class DashboardComponent implements OnInit {

  showHideDom = false;
  url;
  authToken;
  customerAuthKey;
  merchantId;
  frontEndUrl = environment.frontEndUrl;
  merchantName;
  loading = false;
  primaryColour = '#ffffff';
  secondaryColour = '#ccc';
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;

  constructor(private mainService: MainServiceService, private router: Router, private route: ActivatedRoute, private paymentService: PaymentService) { }

  ngOnInit(): void {
    this.loading = false;
    var decodedUrl = decodeURIComponent(window.document.URL);
    this.url = decodedUrl.split("?");
    this.merchantId = "";
    this.customerAuthKey = "";
    this.merchantName = "";

    if (this.url[0] !== this.frontEndUrl + "/dashboard/usagelogs" && this.url[0] !== this.frontEndUrl +
      "/dashboard/accountsettings" && this.url[0] !== this.frontEndUrl + "/dashboard/how-to-use" && this.url[0] !== this.frontEndUrl + "/dashboard/payment-safety-info" && this.url[0] !== this.frontEndUrl + "/dashboard/createform" && this.url[0] !== this.frontEndUrl + "/dashboard/payments" && this.url[0] !== this.frontEndUrl + "/dashboard/email" && this.url[0] !== this.frontEndUrl + "/" && this.url[0] !== this.frontEndUrl + "/dashboard") {
      this.showHideDom = true;

      this.route.queryParams.subscribe(parameter => {

        var keys = Object.keys(parameter)
        if (parameter.paymentFormId) {
          this.paymentService.paymentId = parameter.paymentFormId;
        }
      })

    }
    else {

      this.route.queryParams.subscribe(parameter => {

        var keys = Object.keys(parameter)
        if (keys.length > 0 && parameter.token) {

          if (parameter.merchantName) {
            this.merchantName = parameter.merchantName;
            sessionStorage.setItem("MerchantName", parameter.merchantName);
          }
          if (parameter.token != undefined && !sessionStorage.getItem("AuthToken")) {
            this.authToken = parameter.token;

            this.loading = true;
            this.mainService.verifySession(parameter.token).subscribe((response: any) => {
              this.loading = false;

              if (response.statusCode == 200) {
                sessionStorage.setItem("AuthToken", parameter.token);
              }
              else {
                window.location.href = environment.cloverUrl
                // window.location.href = "https://www.clover.com"
              }

            }, err => {
              this.loading = false;
              window.location.href = environment.cloverUrl
              // window.location.href = "https://www.clover.com"
            });

          }
        }
      })

    }
  }

  toggleSideNav() {
    this.mainService.changeShowSideNavValue(true);
  }

}
