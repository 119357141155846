import { Component, OnInit, Output, OnChanges, EventEmitter, HostListener } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { MainServiceService } from '../../../services/main-service.service';
import { Subscription } from 'rxjs';
import { Router, RouteReuseStrategy } from "@angular/router";
import { ngxLoadingAnimationTypes, NgxLoadingComponent } from 'ngx-loading';
import { NotificationService } from '../../../services/notification.service';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.css']
})
export class SideNavComponent implements OnInit {

  showSideMenu;
  subscription: Subscription;
  mq = window.matchMedia("(max-width: 991px)");
  scrWidth;
  merchantName;

  loading = false;
  primaryColour = '#ffffff';
  secondaryColour = '#ccc';
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;

  @Output() routeUrl = new EventEmitter();

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {

    this.scrWidth = window.innerWidth;

    if (this.scrWidth > 991) {
      this.mainService.changeShowSideNavValue(true);
      this.subscription = this.mainService.getShowSideNavValue().subscribe(data => {

        if (data) {
          this.showSideMenu = data.showSideNav;
        }
      });
    }
    else {
      this.mainService.changeShowSideNavValue(false);
      this.subscription = this.mainService.getShowSideNavValue().subscribe(data => {

        if (data) {
          this.showSideMenu = data.showSideNav;
        }
      });
    }
  }

  constructor(private mainService: MainServiceService, private router: Router, private notifyService: NotificationService) {
    this.subscription = this.mainService.getShowSideNavValue().subscribe(data => {

      if (data) {
        this.showSideMenu = data.showSideNav;
      }
    });
  }

  ngOnInit(): void {

    this.loading = false;
    this.showSideMenu = true;
    if (this.mq.matches == false) {
      this.mainService.changeShowSideNavValue(true);
      this.showSideMenu = true;
    }
    else {
      this.mainService.changeShowSideNavValue(false);
      this.showSideMenu = false;
    }
    this.merchantName = sessionStorage.getItem("MerchantName")
  }

  hideSideNav() {
    this.showSideMenu = false;
    this.mainService.changeShowSideNavValue(false);
  }

  logoutClicked() {
    this.loading = true;

    this.mainService.logout().subscribe((response: any) => {
      console.log(response)
      sessionStorage.clear();
      location.reload();
    }, err => {
      this.notifyService.showError("Error logging out. Please try after sometime", "");
      this.loading = false;
    });
  }
}
