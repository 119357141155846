<div class="beauty_form ">
    <!-- Navbar header -->

    <!--  End Header -->
    <section class="col-md-7 col-lg-5 mx-auto col-12 page_box1 form_beauty overflow-hidden rounded-0">
        <div class="row">
            <div class="col-lg-12">
                <div class="card rounded-0">
                    <!-- Page Header-->
                    <div class="card-header p-0">
                        <div class="form_detials">

                            <div class="col-md-12 col-sm-12 text-center sally_beauty">
                                <h5>Please provide the Credit Card details</h5>
                            </div>
                        </div>
                    </div>
                    <!-- End Page Header-->
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="container1">

                                    <form action="" method="post" id="payment-form" (ngSubmit)="pay($event)">
                                        <div class="form-row">
                                            <div id="card-number" class="field card-number col-md-12">
                                            </div>
                                            <div class="errors" id="card-number-errors" role="alert">
                                            </div>

                                        </div>
                                        <div class="form-row ">
                                            <div id="card-date" class="field col-md-12">
                                            </div>
                                            <div class="errors" id="card-date-errors" role="alert"></div>
                                        </div>
                                        <div class="form-row ">
                                            <div id="card-cvv" class="field col-md-12">
                                            </div>
                                            <div class="errors" id="card-cvv-errors" role="alert"></div>
                                        </div>
                                        <div class="form-row ">
                                            <div id="card-postal-code" class="field col-md-12"></div>
                                            <div class="errors" id="card-postal-code-errors" role="alert">
                                            </div>
                                        </div>

                                        <div class="row">
                                          <div class="col-sm-12 col-md-12 col-lg-12 ">
                                              <div class="form-group ">
                                                  <ngx-recaptcha2 [siteKey]="siteKey" [(ngModel)]="recaptcha" [ngModelOptions]="{standalone: true}">
                                                  </ngx-recaptcha2>
                                                  <div class="input-errors" *ngIf="submitted && !recaptcha">
                                                      Please click on the captcha.
                                                  </div>
                                              </div>
                                          </div>
                                        </div>

                                        <div class="col-sm-12 co-md-12">
                                            <div class="form-group mt-2">
                                                <input type="submit" value="Pay"
                                                    class="btn btn-primary w-100 rounded-0 p-3">

                                            </div>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </section>
</div>

<ngx-loading [show]="loading" class="overlay" *ngIf="loading"
    [config]="{animationType: ngxLoadingAnimationTypes.circle, primaryColour: primaryColour, secondaryColour: secondaryColour, backdropBorderRadius: '3px'}">
</ngx-loading>
