import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClientXsrfModule, HttpXsrfTokenExtractor, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MyMaterialModule } from './material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DashboardComponent } from './paymentApp/dashboard/dashboard.component';
import { SideNavComponent } from './paymentApp/dashboard/side-nav/side-nav.component';
import { UsageLogsComponent } from './paymentApp/dashboard/usage-logs/usage-logs.component';
import { AccountSettingsComponent } from './paymentApp/dashboard/account-settings/account-settings.component';
import { HowToUseComponent } from './paymentApp/dashboard/how-to-use/how-to-use.component';
import { PaymentSafetyInfoComponent } from './paymentApp/dashboard/payment-safety-info/payment-safety-info.component';
import { CreateNewFormComponent } from './paymentApp/dashboard/create-new-form/create-new-form.component';
import { SessionStorageService } from './encryptDecryptService/sessionStorage.service';
import { MainServiceService } from './services/main-service.service';
import { HttpserviceService } from './services/httpservice.service';
import { PaymentService } from './services/payment.service';
import { OnlyLoggedInUserGuard, paymentGuard, paymentGuardSuccess, verifySessionGuard } from './guards/guards';
import { PagenotfoundComponent } from './paymentApp/dashboard/pagenotfound/pagenotfound.component';
import { PaymentsFormComponent, paymentsDialog, DeleteDialogComponent } from './paymentApp/dashboard/payments-form/payments-form.component';
import { SendEmailComponent } from './paymentApp/dashboard/send-email/send-email.component';
import { CustomerFormComponent } from './paymentApp/customer-form/customer-form.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { CreditCardFormComponent } from './paymentApp/credit-card-form/credit-card-form.component';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { ToastrModule } from 'ngx-toastr';
import { PaymentSuccessComponent } from './paymentApp/payment-success/payment-success.component';
import { NgxCaptchaModule } from 'ngx-captcha';
import { CustomInterceptor } from './custom.interceptor'
// import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from "ng-recaptcha";
import { environment } from 'src/environments/environment';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    SideNavComponent,
    UsageLogsComponent,
    AccountSettingsComponent,
    HowToUseComponent,
    PaymentSafetyInfoComponent,
    CreateNewFormComponent,
    PagenotfoundComponent,
    PaymentsFormComponent,
    SendEmailComponent,
    CustomerFormComponent,
    CreditCardFormComponent,
    paymentsDialog,
    DeleteDialogComponent,
    PaymentSuccessComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MyMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxPaginationModule,
    NgxLoadingModule.forRoot({}),
    ToastrModule.forRoot(),
    NgxCaptchaModule,
    // RecaptchaV3Module,
  ],
  entryComponents: [
    paymentsDialog,
    DeleteDialogComponent
  ],
  providers: [
    SessionStorageService,
    MainServiceService,
    HttpserviceService,
    PaymentService,
    OnlyLoggedInUserGuard,
    paymentGuard,
    paymentGuardSuccess,
    verifySessionGuard,
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: false } },
    { provide: HTTP_INTERCEPTORS, useClass: CustomInterceptor, multi: true },
    // { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.siteKey }
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }
