import { Injectable, HostListener } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpserviceService } from './httpservice.service';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MainServiceService {

  authToken;
  showSideNav;
  isloggedIn = false;
  isCreateRoute = false;
  private subject = new Subject<any>();
  api = environment.apiUrl;
  httpOptions;
  backButton = false;

  constructor(private httpService: HttpserviceService) { }
  changeShowSideNavValue(value) {
    this.subject.next({ showSideNav: value });
  }

  getShowSideNavValue() {
    return this.subject.asObservable();
  }

  verified = false;
  verifySessionApi = "/v1/merchant/verifySession";
  verifySession(token) {

    var authToken = token;
    var api = this.api + this.verifySessionApi;
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': authToken
      }),
      xhrFields: {
        withCredentials: true,
      }
    };

    var data = {
      api,
      httpOptions: this.httpOptions
    }
    return this.httpService.getApi(data);
  }

  createFormPostApi = "/v1/paymentForm";
  createForm(body) {
    this.httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': sessionStorage.getItem("AuthToken") })
    };
    var api = this.api + this.createFormPostApi;
    var data = {
      body,
      "api": api,
      httpOptions: this.httpOptions
    }
    return this.httpService.postApi(data);
  }

  getPaymentsData(pageNum, noOfRecords) {

    var recordsPerPage = noOfRecords;
    var pageNumber = pageNum;
    var api = this.api + this.createFormPostApi + "/?limit=" + recordsPerPage + "&pageNumber=" + pageNumber;
    this.httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': sessionStorage.getItem("AuthToken") })
    };

    var data = {
      api,
      httpOptions: this.httpOptions
    }
    return this.httpService.getApi(data);
  }

  deletePaymentFormApi = "/v1/paymentForm/";
  deletePayement(paymentId) {
    var body = {
      "paymentFromId": paymentId
    }
    this.httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': sessionStorage.getItem("AuthToken") })
    };
    var api = this.api + this.deletePaymentFormApi + paymentId;
    var data = {
      api,
      httpOptions: this.httpOptions
    }
    return this.httpService.deleteApi(data);
  }

  editPaymentData(body) {
    var id = body._id;
    var editObj = body;
    delete editObj['_id'];

    this.httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': sessionStorage.getItem("AuthToken") })
    };
    var api = this.api + this.deletePaymentFormApi + id;

    var data = {
      "body": editObj,
      "api": api,
      httpOptions: this.httpOptions
    }
    return this.httpService.editApi(data);
  }

  accountSettingsUrl = "/v1/merchant";
  editAccountSettings(body) {

    var api = this.api + this.accountSettingsUrl;
    this.httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': sessionStorage.getItem("AuthToken") })
    };
    var data = {
      "body": body,
      "api": api,
      httpOptions: this.httpOptions
    }
    return this.httpService.editApi(data);
  }

  usageLog = "/v1/transaction";
  getUsageLogs(pageNum, searchString?) {

    var recordsPerPage = 5;
    var pageNumber = pageNum;
    this.httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': sessionStorage.getItem("AuthToken") })
    };

    var api;
    if (searchString) {
      api = this.api + this.usageLog + "/?limit=" + recordsPerPage + "&pageNumber=" + pageNumber + "&searchString=" + searchString;
    } else {
      api = this.api + this.usageLog + "/?limit=" + recordsPerPage + "&pageNumber=" + pageNumber;
    }

    var data = {
      api,
      httpOptions: this.httpOptions
    }
    return this.httpService.getApi(data);
  }

  accountSetting = "/v1/merchant";
  getAccountSettings(token?:string) {
    var api = this.api + this.accountSetting;
    this.httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': token || sessionStorage.getItem("AuthToken") })
    };

    var data = {
      api,
      httpOptions: this.httpOptions
    }
    return this.httpService.getApi(data);
  }

  logoutApi = "/v1/merchant/logout";
  logout() {
    var api = this.api + this.logoutApi;
    this.httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': sessionStorage.getItem("AuthToken") })
    };

    var data = {
      api,
      body: {},
      httpOptions: this.httpOptions
    }
    return this.httpService.postApi(data);
  }

  sendEmailApi = "/v1/merchant/sendLinkToCustomer";
  sendEmail(body) {
    var api = this.api + this.sendEmailApi;
    this.httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': sessionStorage.getItem("AuthToken") })
    };
    var data = {
      api,
      body: body,
      httpOptions: this.httpOptions
    }
    return this.httpService.postApi(data);
  }

  uploadLogoApi = "/v1/file/upload";
  uploadLogo(body) {
    var api = this.api + this.uploadLogoApi;
    this.httpOptions = {
      headers: new HttpHeaders({ 'Authorization': sessionStorage.getItem("AuthToken") })
    };
    var data = {
      api,
      body: body,
      httpOptions: this.httpOptions
    }
    return this.httpService.postApi(data);
  }
}
