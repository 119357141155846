<section class="page_box shadow overflow-hidden">
    <div class="row">
        <div class="col-lg-12">
            <div class="card account_set">
                <!-- Page Header-->
                <div class="card-header">
                    <div class="row align-items-center">

                        <div class="col-md-12 col-sm-12">
                            <h3 class="d-inline-block mb-0">Account Settings</h3>
                        </div>

                    </div>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="card">
                                <!-- enctype='multipart/form-data' -->
                                <form [formGroup]="accountSettingForm">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12 col-lg-12">
                                            <div class="form-group">
                                                <label class="col-sm-12 col-md-12 col-lg-12 p-0">Enter store email
                                                    (for notice of payments when they occur)
                                                </label>
                                                <input type="email" placeholder="" formControlName="firstEmail"
                                                    class="form-control col-sm-12 col-md-12 col-lg-6">
                                                <!-- [ngClass]="{ 'is-invalid': submitted && f.firstEmail.errors }" -->
                                                <div class="input-errors"
                                                    *ngIf="submitted && f.firstEmail.errors?.required">
                                                    Email is required
                                                </div>
                                                <div class="input-errors"
                                                    *ngIf="submitted && f.firstEmail.errors?.email">
                                                    Email must be a valid email address
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12 col-lg-12">
                                            <div class="form-group">
                                                <label class="col-sm-12 col-md-12 col-lg-12 p-0"> Enter a 2nd
                                                    store email (Optional)</label>
                                                <input type="email" placeholder="" formControlName="secondEmail"
                                                    class="form-control col-sm-12 col-md-12 col-lg-6">
                                                <div class="input-errors"
                                                    *ngIf="submitted && f.secondEmail.errors?.email">
                                                    Email must be a valid email address
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12 col-lg-12">
                                            <div class="form-group">
                                                <label class="col-sm-12 col-md-12 col-lg-12 p-0"> Message to be
                                                    sent via email to your customer after payment(Optional)</label>
                                                <input type="email" placeholder="" formControlName="messageAfterPayment"
                                                    class="form-control col-sm-12 col-md-12 col-lg-6">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                      <div class="col-sm-12 col-md-12 col-lg-12">
                                          <div class="form-group">
                                              <label class="col-sm-12 col-md-12 col-lg-12 p-0">Card Processing Fee (%)</label>
                                              <select aria-expanded="true" name="cardProcessingFee" formControlName="cardProcessingFee" class="dropdown-toggle form-control col-sm-12 col-md-12 col-lg-6">
                                                  <option class="dropdown-item" value="2.75">2.75%</option>
                                                  <option class="dropdown-item" value="3">3.00%</option>
                                                  <option class="dropdown-item" value="3.25">3.25%</option>
                                                  <option selected class="dropdown-item" value="3.5">3.50%</option>
                                              </select>
                                          </div>
                                      </div>
                                  </div>
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12 col-lg-12">
                                            <div class="form-group">
                                                <div class="group_btns">
                                                    <button class="btn btn-md btn-primary"
                                                        (click)="fileInput.click()">Choose
                                                        logo</button>

                                                    <button class="btn btn-md btn-primary ml-3"
                                                        (click)="uploadLogo($event)"
                                                        [ngClass]="(!file || uploaded)? 'disableBtn': ''">Upload</button>
                                                </div>
                                                <div class="file_preview"><img [src]="url" *ngIf="url"
                                                        class="imageHeight"></div>
                                                <input type='file' style="display: none" (change)="onSelectFile($event)"
                                                    #fileInput>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-sm-12 co-md-12">
                                            <div class="form-group mt-2">
                                                <input type="submit" value="Save" class="btn btn-primary"
                                                    (click)="onSave() "
                                                    [ngClass]="(accountSettingForm.touched || accountSettingForm.dirty) && !diableSave? '': 'disableBtn'">
                                                <!-- [disabled]="!accountSettingForm.valid" -->
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>

<ngx-loading [show]="loading" class="overlay" *ngIf="loading"
    [config]="{animationType: ngxLoadingAnimationTypes.circle, primaryColour: primaryColour, secondaryColour: secondaryColour, backdropBorderRadius: '3px'}">
</ngx-loading>
