<section class="page_box shadow overflow-hidden">
    <div class="row">
        <div class="col-lg-12">
            <div class="card account_set">
                <!-- Page Header-->
                <div class="card-header">
                    <div class="row align-items-center">
                        <div class="col-md-12 col-sm-12">
                            <h3 class="d-inline-block mb-0">Payment Forms</h3>
                        </div>
                    </div>
                </div>
                <!-- End Page Header-->

                <div class="card-body">
                    <div class="table-responsive mobile_card" *ngIf="data?.length">
                        <table class="table dataTable table-hover" id="table_data" style="width:100%">
                            <thead>
                                <tr>
                                    <th scope="col" class=" sorting_asc">Created Date
                                        <!-- <div class="sorting">
                                            <i class="active"><img src="../../../../assets/icons/up_arrow.png"
                                                    alt="sort"></i>
                                            <i><img src="../../../../assets/icons/down_arrow.png" alt="sort"></i>
                                        </div> -->
                                    </th>
                                    <th scope="col" class=" sorting_asc">Form Name
                                        <!-- <div class="sorting">
                                            <i class="active"><img src="../../../../assets/icons/up_arrow.png"
                                                    alt="sort"></i>
                                            <i><img src="../../../../assets/icons/down_arrow.png" alt="sort"></i>
                                        </div> -->
                                    </th>
                                    <th scope="col" class="text-center sorting_asc">$ Amount
                                        <!-- <div class="sorting">
                                            <i class="active"><img src="../../../../assets/icons/up_arrow.png"
                                                    alt="sort"></i>
                                            <i><img src="../../../../assets/icons/down_arrow.png" alt="sort"></i>
                                        </div> -->
                                    </th>
                                    <th scope="col" class="text-center sorting_asc">Action
                                        <!-- <div class="sorting">
                                            <i class="active"><img src="../../../../assets/icons/up_arrow.png"
                                                    alt="sort"></i>
                                            <i><img src="../../../../assets/icons/down_arrow.png" alt="sort"></i>
                                        </div> -->
                                    </th>
                                    <th scope="col" class="text-center sorting_asc">Url
                                        <!-- <div class="sorting">
                                            <i class="active"><img src="../../../../assets/icons/up_arrow.png"
                                                    alt="sort"></i>
                                            <i><img src="../../../../assets/icons/down_arrow.png" alt="sort"></i>
                                        </div> -->
                                    </th>
                                </tr>
                            </thead>

                            <!-- *ngIf="data?.length" -->
                            <tbody>
                                <tr
                                    *ngFor="let item of data  | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalRecords }">
                                    <td data-th="Created Date" title={{item.createdAt}}>
                                        {{ item.createdAt}} </td>
                                    <td data-th="Form Name" title={{item.name}}>
                                        <div class=" ellipses nameWidth"> {{ item.name}}</div>
                                    </td>

                                    <td data-th="$ Amount" class="text-center" title={{item.amount}}>
                                        {{ item.amount? item.amount: 'NA'  }} </td>

                                    <td data-th="Action" class="text-center">

                                        <div class="iconDisplay">

                                            <div (click)="editData(item)" title="Edit"><i class="edit_icon m-1"><img
                                                        src="../../../../assets/icons/edit_icon.svg" alt="" /></i></div>
                                            <div (click)="deleteRow(item)" title="Delete"><i class="set_icon m-1"><img
                                                        src="../../../../assets/icons/delete_icon.svg" alt="" /></i>
                                            </div>
                                        </div>
                                    </td>

                                    <td data-th="Url" class="text-center " title={{item.url}}>
                                        <div class="ellipses urlWidth"> <a href="{{ item.url? item.url: 'NA'  }}">
                                                {{ item.url? item.url: 'NA'  }}</a></div>
                                    </td>

                                </tr>
                            </tbody>

                            <!-- <tbody *ngIf="!data?.length">
                                <tr>
                                    No record found
                                </tr>
                            </tbody> -->

                        </table>
                        <pagination-controls class="paginationAlign" (pageChange)="nextPage($event)">
                        </pagination-controls>

                    </div>

                    <div *ngIf="!data?.length">No records found</div>
                </div>
            </div>
        </div>
    </div>
</section>

<ngx-loading [show]="loading" class="overlay" *ngIf="loading"
    [config]="{animationType: ngxLoadingAnimationTypes.circle, primaryColour: primaryColour, secondaryColour: secondaryColour, backdropBorderRadius: '3px'}">
</ngx-loading>