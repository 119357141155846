<nav class="side-navbar" [ngClass]="showSideMenu? 'show': 'hide'">
    <div class="menu-group">
        <!-- Sidebar Header    -->
        <div class="sidenav-header text-center">
            <a>
                <div class="d-flex align-items-center">
                    <img src="../../../../assets/hay_logo.png" alt="" class="img-fluid" />
                    <h3 class="sidenav-heading">Hay, <strong>Now</strong></h3>
                </div>
            </a>
            <a class="toggle-btn menu-btn hover" (click)=" hideSideNav() "><i class="fa fa-close"></i></a>
        </div>
        <!-- Sidebar Navigation Menus-->
        <div class="main-menu">
            <ul id="side-main-menu" class="side-menu list-unstyled">
                <li [routerLinkActive]="['active']"><a [routerLink]="['createform']"><i><img
                                src="../../../../assets/icons/cus_icon.png" alt="Create New" /></i> Create New
                    </a></li>
                <li [routerLinkActive]="['active']"><a [routerLink]="['payments']"><i><img
                                src="../../../../assets/icons/ss_icon.png" alt=" Payment Forms " /></i> Payment
                        Forms </a></li>
                <li [routerLinkActive]="['active']"><a [routerLink]="['accountsettings']"><i><img
                                src="../../../../assets/icons/ua_icon.png" alt="Account Settings" /></i> Account
                        Settings </a>
                </li>
                <li [routerLinkActive]="['active']"><a [routerLink]="['usagelogs']"><i><img
                                src="../../../../assets/icons/sc_icon.png" alt="Usage logs" /></i> Usage
                        logs</a></li>
                <li [routerLinkActive]="['active']"><a [routerLink]="['how-to-use']"><i><img
                                src="../../../../assets/icons/activites.png" alt="How to use" /></i> How to use</a></li>
                <li [routerLinkActive]="['active']"><a [routerLink]="['payment-safety-info']"><i><img
                                src="../../../../assets/icons/payment_safety.png" alt="Info about payment safety" /></i>
                        Info
                        about
                        payment safety</a></li>
                <li [routerLinkActive]="['active']"><a [routerLink]="['email']"><i><img
                                src="../../../../assets/icons/send_email.png" alt="Quick send via Email or Text" /></i>
                        Quick send via email</a></li>
            </ul>
            <ul class="sidemainmenu">
                <li [routerLinkActive]="['active']">
                    <span>Logged in as {{ merchantName }}</span>
                    <a class="pointer" (click)="logoutClicked()"><i><img src="../../../../assets/icons/logout.png"
                                alt="logout" /></i>
                        Logout</a>
                </li>
            </ul>
        </div>
        <!-- Sidebar Navigation Menus-->
    </div>
</nav>
<ngx-loading [show]="loading" class="overlay" *ngIf="loading"
    [config]="{animationType: ngxLoadingAnimationTypes.circle, primaryColour: primaryColour, secondaryColour: secondaryColour, backdropBorderRadius: '3px'}">
</ngx-loading>