import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ngxLoadingAnimationTypes, NgxLoadingComponent } from 'ngx-loading';
import { NotificationService } from '../../../services/notification.service';
import { MainServiceService } from '../../../services/main-service.service';

@Component({
  selector: 'app-send-email',
  templateUrl: './send-email.component.html',
  styleUrls: ['./send-email.component.css']
})
export class SendEmailComponent implements OnInit {

  emailForm;
  submitted = false;
  body;
  payNowSelected = false;
  donateNowSelected = false;
  loading = false;
  primaryColour = '#ffffff';
  secondaryColour = '#ccc';
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  paymentFormId = [];
  // CONSTANTS.BUTTON_TYPES = {
  //   PAY_NOW: 1,
  //   DONATE_NOW: 2
  // };

  constructor(private mainService: MainServiceService, private formBuilder: FormBuilder, private notifyService: NotificationService) { }

  validateEmail(c) {
    let email = /^(\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/
    let phone = /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/

    // return (email.test(c.value) || phone.test(c.value)) ? null : {
    //   validateEmail: {
    //     invalid: true
    //   }
    // }

    return (email.test(c.value)) ? null : {
      validateEmail: {
        invalid: true
      }
    }
  }

  ngOnInit(): void {

    this.payNowSelected = false;
    this.donateNowSelected = false;

    this.emailForm = this.formBuilder.group({
      customerName: ['', Validators.required],
      emailPhoneNo: ['', [Validators.required, this.validateEmail]],
      paymentId: ['', Validators.required],
      // message: ['', Validators.required],
      buttonType: ['']
    });

    this.getPaymentFormId();
  }
  get f() {
    return this.emailForm.controls;
  }

  paymentFormIdChange(event) {
  }
  payNow() {
    this.emailForm.patchValue({ buttonType: 1 });
    this.payNowSelected = true;
    this.donateNowSelected = false;
    this.payDonateOption = false;
  }

  donateNow() {
    this.emailForm.patchValue({ buttonType: 2 });
    this.payNowSelected = false;
    this.donateNowSelected = true;
    this.payDonateOption = false;
  }

  pageNumber = 1;
  noOfRecords = 15;
  getPaymentFormId() {

    this.paymentFormId = [];
    this.loading = true;
    this.mainService.getPaymentsData(this.pageNumber, this.noOfRecords).subscribe((response: any) => {
      this.loading = false;

      var formName;
      var formId;
      for (var i = 0; i < response.data.length; i++) {
        var body = {
          "formName": response.data[i].name,
          "formId": response.data[i]._id
        }
        this.paymentFormId.push(body);
      }

    }, err => {
      this.loading = false;
    })
  }

  payDonateOption = false;
  sendEmail() {
    this.submitted = true;

    if (typeof this.emailForm.value.buttonType != "number" || this.emailForm.value.buttonType == "") {
      this.payDonateOption = true;
    }
    else {
      this.payDonateOption = false;
    }

    // stop here if form is invalid
    if (this.emailForm.invalid) {
      return;
    }
    else {

      if (typeof this.emailForm.value.buttonType != "number" || this.emailForm.value.buttonType == "") {
        this.payDonateOption = true;
      }
      else {
        this.payDonateOption = false;

        this.body = {
          "customerName": this.emailForm.value.customerName.trim(),
          "paymentFormId": this.emailForm.value.paymentId,
          // "message": this.emailForm.value.message.trim(),
          "buttonType": this.emailForm.value.buttonType
        };

        var emailRegex = (/^(\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/)
        var phoneNumberRegex = (/^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/)

        if (emailRegex.test(this.emailForm.value.emailPhoneNo)) {
          this.body.email = this.emailForm.value.emailPhoneNo;
        }

        if (phoneNumberRegex.test(this.emailForm.value.emailPhoneNo)) {
          this.body.phoneNumber = this.emailForm.value.emailPhoneNo;
        }

        this.loading = true;
        this.mainService.sendEmail(this.body).subscribe((response: any) => {
          this.loading = false;

          if (response.statusCode == 200 && response.status == true) {
            this.donateNowSelected = false;
            this.payNowSelected = false;
            this.submitted = false;
            this.payDonateOption = false;

            this.emailForm.patchValue({ customerName: '' });
            this.emailForm.patchValue({ emailPhoneNo: '' });
            this.emailForm.patchValue({ paymentId: '' });
            // this.emailForm.patchValue({ message: '' });
            this.emailForm.patchValue({ buttonType: 0 });

            this.notifyService.showSuccess(response.msg, "");

          }
        }, err => {
          this.loading = false;
          this.notifyService.showError("Error sending the email. Please try after sometime.", "");
        })
      }

    }
  }

}
