import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class HttpserviceService {

  constructor(private http: HttpClient, ) { }

  getApi(data) {
    var httpOptions = data.httpOptions;
    return this.http.get(data.api, httpOptions);
  }

  postApi(data) {
    var api = data.api;
    var body = data.body;
    var httpOptions = data.httpOptions;
    return this.http.post<any>(api, body, httpOptions);
  }

  deleteApi(data) {
    var api = data.api;
    var httpOptions = data.httpOptions;
    return this.http.delete(api, httpOptions)
  }

  editApi(data) {
    return this.http.put(data.api, data.body, data.httpOptions)
  }

}
