<div mat-dialog-content class="content modal_popup">

    <h2 mat-dialog-title class="heading"> Edit the infomation </h2>
    <div class="row">
        <div class="col-md-12">
            <label class="label"> Date </label>
            <div class="form-group">
                <input [(ngModel)]="paymentData.createdAt" disabled class="form-control">
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <label class="label"> Form Name </label>
            <div class="form-group">
                <input type="text" [(ngModel)]="paymentData.name" class="form-control"
                    (keyup)="formNameChange(paymentData.name)">
                <div class="input-errors" *ngIf="formNameEmpty">
                    Form Name is required.
                </div>
            </div>
        </div>
    </div>


    <div class="row">
        <div class="col-md-12">
            <label class="label"> Amount </label>
            <div class="form-group">
                <input type="number" [(ngModel)]="paymentData.amount" class="form-control">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <label class="label">Url</label>
            <div class="form-group">
                <input [(ngModel)]="paymentData.url" disabled class="form-control">
            </div>
        </div>
    </div>

    <div mat-dialog-actions class="row">
        <div mat-dialog-actions class="col-md-12 py-0 justify-content-center buttonGroup">
            <button mat-raised-button class="btn btn-md btn-primary" (click)="saveData()"> Save </button>
            <button mat-raised-button class="btn btn-md btn-default" cdkFocusInitial (click)="close()"> Close </button>
        </div>
    </div>
</div>