import { Component, OnInit } from '@angular/core';
import { SessionStorageService } from '../../../encryptDecryptService/sessionStorage.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MainServiceService } from '../../../services/main-service.service';
import { Subscription } from 'rxjs';
import { ngxLoadingAnimationTypes, NgxLoadingComponent } from 'ngx-loading';
import { NotificationService } from '../../../services/notification.service';


@Component({
  selector: 'app-usage-logs',
  templateUrl: './usage-logs.component.html',
  styleUrls: ['./usage-logs.component.css']
})
export class UsageLogsComponent implements OnInit {

  constructor(private sessionStorageService: SessionStorageService, private mainService: MainServiceService, private notifyService: NotificationService) { }

  data = [];
  totalRecords;
  currentPage;
  itemsPerPage;
  loading = false;
  primaryColour = '#ffffff';
  secondaryColour = '#ccc';
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  search = "";

  ngOnInit(): void {
    this.loading = false;
    this.currentPage = 1;
    this.itemsPerPage = 5;
    this.getUsageLog(this.currentPage);
  }

  getUsageLog(pageNumber, search?) {
    this.loading = true;
    this.mainService.getUsageLogs(pageNumber, search).subscribe((response: any) => {
      this.loading = false;
      var res = response;
      this.totalRecords = response.totalDocuments;
      this.data = [];

      if (response.data.length > 0) {
        for (var i = 0; i < response.data.length; i++) {
          this.data.push(response.data[i]);
          var timeOffset = new Date(response.data[i].createdAt).getTimezoneOffset();
          var newDate = new Date(response.data[i].createdAt).getTime() - (timeOffset * 60);
          var date = new Date(newDate);
          this.data[i].createdAt = new Date(response.data[i].createdAt).toLocaleDateString();
        }
      }
    }, err => {
      this.loading = false;
      this.notifyService.showError("Error fetching data. Please try after sometime.", "")
    })
  }

  searchLogs(event) {
    console.log(event)
    console.log(event.target.value)
    this.currentPage = 1;
    this.getUsageLog(this.currentPage, event.target.value)
  }

  nextPage(event) {
    this.currentPage = event;
    this.getUsageLog(event, this.search);
  }

}
