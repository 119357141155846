import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MainServiceService } from '../../../services/main-service.service';
import { Subscription } from 'rxjs';
import { ngxLoadingAnimationTypes, NgxLoadingComponent } from 'ngx-loading';
import { NotificationService } from '../../../services/notification.service';

@Component({
  selector: 'app-payments-form',
  templateUrl: './payments-form.component.html',
  styleUrls: ['./payments-form.component.css']
})

export class PaymentsFormComponent implements OnInit {

  totalRecords;
  currentPage;
  itemsPerPage;
  loading = false;
  primaryColour = '#ffffff';
  secondaryColour = '#ccc';
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  constructor(public dialog: MatDialog, private mainService: MainServiceService, private notifyService: NotificationService) { }

  data = [];
  ngOnInit(): void {
    this.currentPage = 1;
    this.itemsPerPage = 5;
    this.loading = false;
    this.getPayments(this.currentPage);
  }

  getPayments(pageNumber) {

    this.loading = true;
    this.mainService.getPaymentsData(pageNumber, this.itemsPerPage).subscribe((response: any) => {
      this.loading = false;

      var res = response;
      this.totalRecords = response.totalDocuments;
      this.data = [];

      if (response.data.length > 0) {
        for (var i = 0; i < response.data.length; i++) {
          this.data.push(response.data[i]);


          var timeOffset = new Date(response.data[i].createdAt).getTimezoneOffset();
          var newDate = new Date(response.data[i].createdAt).getTime() - (timeOffset * 60);
          var date = new Date(newDate);

          this.data[i].createdAt = new Date(response.data[i].createdAt).toLocaleDateString();
        }
      }

    }, err => {
      this.loading = false;
      this.notifyService.showError("Error fetching data. Please try after sometime.", "")
    })
  }

  deleteRow(data) {

    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      disableClose: true,
      hasBackdrop: true,
      data: data
    });

    dialogRef.afterClosed().subscribe(response => {

      if (response && response.toLowerCase() == "yes") {
        this.loading = true;
        this.mainService.deletePayement(data._id).subscribe((response: any) => {
          this.loading = false;

          if (response.status == true && response.statusCode == "200") {
            this.getPayments(this.currentPage);
            this.notifyService.showSuccess("Data deleted successfully !!", "");
          }
        }, err => {
          this.loading = false;
          this.notifyService.showError("Error in deleting data. Try after some time.", "");
        });
      }
    });
  }

  nextPage(event) {
    this.currentPage = event;
    this.getPayments(event);
  }

  editResponse;
  editData(data) {
    const dialogRef = this.dialog.open(paymentsDialog, {
      disableClose: true,
      hasBackdrop: true,
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        // if (typeof result == "object") {
        this.loading = true;
        this.mainService.editPaymentData(result).subscribe((response: any) => {
          this.loading = false;
          if (response) {
            this.editResponse = response;

            if (response.status == true && response.statusCode == "200") {
              this.notifyService.showSuccess("Data is saved successfully !!", "");
              this.getPayments(this.currentPage);
            }
          }
        }, err => {
          this.loading = false;
          this.notifyService.showError(err.error.msg, "");
        })
        // }
      }
      else {
        this.getPayments(this.currentPage);
      }
    });
  }

}

// **************   Edit dialog
@Component({
  selector: 'payments-dialog',
  templateUrl: '../../../dialogs/paymentsDialog.html',
  styleUrls: ['../../../dialogs/paymentsDialog.scss']
})
export class paymentsDialog {

  paymentData;
  formNameEmpty = false;
  constructor(
    private dialogRef: MatDialogRef<paymentsDialog>,
    @Inject(MAT_DIALOG_DATA) private data1: any) {

    if (data1) {
      this.paymentData = data1;
    }
  }

  formNameChange(event) {
    if (this.paymentData.name == "" || this.paymentData.name == null || this.paymentData.name == undefined) {
      this.formNameEmpty = true;
    }
    else {
      this.formNameEmpty = false;
    }
  }

  saveData() {

    if (this.paymentData.name == "" || this.paymentData.name == null || this.paymentData.name == undefined) {
      this.formNameEmpty = true;
      return;
    }
    this.formNameEmpty = false;
    var obj = {
      "_id": this.paymentData._id,
      "name": this.paymentData.name,
      "amount": this.paymentData.amount
    }

    if (obj.amount == null || obj.amount == 0 || obj.amount == "") {
      obj.amount = 0;
      delete obj['amount'];
    }
    this.dialogRef.close(obj);
  }

  close() {
    this.dialogRef.close();
  }
}


// **************   Delete dialog
@Component({
  selector: 'app-delete-dialog',
  templateUrl: '../../../dialogs/delete-dialog/delete-dialog.component.html',
  styleUrls: ['../../../dialogs/delete-dialog/delete-dialog.component.scss']
})
export class DeleteDialogComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<DeleteDialogComponent>) { }

  ngOnInit(): void {
  }

  deleteRecord() {
    this.dialogRef.close('yes');
  }
  close() {
    this.dialogRef.close('no');
  }

}



