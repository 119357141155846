<section class="page_box shadow overflow-hidden">
    <div class="row">
        <div class="col-lg-12">
            <div class="card account_set">
                <!-- Page Header-->
                <div class="card-header">
                    <div class="row align-items-center">

                        <div class="col-md-12 col-sm-12">
                            <h3 class="d-inline-block mb-0">Info About Payment Safety</h3>
                        </div>

                    </div>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="card">
                                <p>
                                    Hay Now's payment links use's Clover-hosted iframe for payments. This is on purpose
                                    to minimize the pci compliance burden to both Appcapable as the developer and to the
                                    merchants that utilize our services. This makes it simple to capture payments online
                                    in a way that will use your existing
                                    merchant account and syncs directly into your clover account. However, when taking
                                    payments online you take risks. Always monitor your accounts for suspicious activity
                                    and report anything suspicious to your merchant provider and to Appcapable. If you
                                    have any questions about how hosted payments work and how they reduce your pci
                                    compliance burden please reach directly out to your merchant services provider.
                                </p>
                                <p>
                                    Please check the clover app market and appcapable.com for ways to reach us.
                                </p>
                                <div class="btn-summary mt-3">
                                    <button class="btn btn-primary" (click)="goToPolicy()">Policy</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>