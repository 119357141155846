import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree, Resolve, ActivatedRoute } from '@angular/router';
import { Injectable } from '@angular/core';
import { MainServiceService } from '../services/main-service.service';
import { Observable, of as ObservableOf, from } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class OnlyLoggedInUserGuard implements CanActivate {

    constructor() { }
    canActivate() {
        sessionStorage.getItem("AuthToken");
        if (sessionStorage.getItem("AuthToken")) {
            return true;
        }
        window.location.href = environment.cloverUrl
        // window.location.href = "https://www.clover.com"
        return false;
    }
}

@Injectable()
export class verifySessionGuard implements CanActivate {

    constructor(private mainService: MainServiceService, private router: Router) { }
    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

        return this.mainService.verifySession(state.root.queryParams.token).pipe(map((response: any) => {
            if (response.statusCode == 200) {
                return true;
            }
            window.location.href = environment.cloverUrl
            // window.location.href = "https://www.clover.com"
            return false;

        }), catchError(err => {
            window.location.href = environment.cloverUrl
            // window.location.href = "https://www.clover.com"
            return ObservableOf(false);
        })
        );
    }
}

@Injectable()
export class paymentGuard implements CanActivate {

    constructor(private router: Router) { }
    canActivate() {
        if (sessionStorage.getItem("apiKey")) {
            return true;
        }
        window.location.href = "https://www.google.com"
        return false;
    }
}

@Injectable()
export class paymentGuardSuccess implements CanActivate {

    constructor(private router: Router) { }
    canActivate() {
        if (sessionStorage.getItem("paymentSuccess")) {
            if (sessionStorage.getItem("paymentSuccess").toLowerCase() == "yes") {
                return true;
            }
        }
        sessionStorage.clear()
        window.location.href = "https://www.google.com"
        return false;
    }
}


